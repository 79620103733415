.archk-incoming-call {

    position: fixed;
    top: $spacer;
    right: $spacer;
    z-index: 999999;
    background: white;
    border-radius: $border-radius-sm;
    width: 350px;

    .archk-incoming-call-wrapper {
        padding: $spacer $spacer * 1;
    }

}

.hotline {
    .archk-incoming-call {
        display: none;
    }
}