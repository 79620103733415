
.g-sidenav-pinned .sidenav .navbar-heading {
    color: $white !important;
}

.navbar {

    padding: 10px 0;

    .navbar-brand-img {
        width: 100px;
    }

    &.navbar-vertical {

        hr {
            border-top-color: darken($white, 90%) !important;
            padding-left: 0;
            padding-right: 0;
        }

        .sidenav-toggler-line {
            background-color: white;
        }

        i {
            color: $white;
        }

        .active {

            .nav-link {
                left: 10px;
            }

        }

        .nav-link {
            transition: all 0.3s;
            left: 0;
            position: relative;
            font-weight: normal !important;
            width: calc(100% - 10px);
            &:hover {

                left: 10px;

            }

        }

    }

    &.navbar-top {

        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 101;

        .show > .nav-link {
            color: lighten($body-color, 15%) !important;

        }

        .nav-link {
            &:hover {
                color: lighten($body-color, 15%) !important;
                cursor: pointer;
            }

            color: $body-color;

        }
        .dropdown-item:hover {
            background-color: darken($blue, 4%);
            color: $white;
            cursor: pointer;
        }

    }

}



.nav-pills {

    .nav-link.active,
    .show > .nav-link a {
        color: darken($white, 10%) !important;
        background: $primary;
        background: linear-gradient(to bottom right, lighten($primary, 25%), lighten($primary, 10%));
        border-color: lighten($primary, 15%);
    }
}

.nav-fill {
    margin-top: $spacer * 2;
    margin-bottom: $spacer * 2;
}
.navbar.navbar-top .dropdown-item:hover {
    color: inherit;
}

.navbar-vertical .navbar-brand > img,
.navbar-vertical .navbar-brand-img {
    max-width: 130%;
    position: relative;
    top: -1px;
}
@media(max-width: 1000px) {
    .navbar-vertical .navbar-brand > img,
    .navbar-vertical .navbar-brand-img {
        max-width: 100%;
        position: relative;
        top: -1px;
    }
}

.nav-master-menu {

    min-width: 600px !important;
    padding: $card-spacer-y $card-spacer-x;

    max-height: 90vh;
    overflow: auto;

    &.calendar-menu {
        min-width: 350px !important;
    }

    ul {
        list-style: none;
        padding-left: 0;
    }

    h2 {
        border-bottom: solid 1px $gray-200;
    }

}

.under-nav {

    // background: lighten($blue, 8%) !important;
    background: $white !important;

    position: fixed;
    top: 52px;
    left: 0;
    right: 0;
    z-index: 100;
    border-top: solid 1px $white;

    .navbar {
        padding: 0;
    }

    .nav-link {
        display: inline-block;
        padding: $spacer * .75 $spacer * 1.4;
        height: 100%;
        border-left: solid 1px rgba(0,0,0,.05);
        font-weight: normal;
        font-size: $font-size-base * .9;

        // color: #656597 !important;
        color: $gray-700 !important;

        i {
            margin-right: 5px !important;
            // color: $text-muted;
        }


        &:hover {
            // background: lighten($blue, 30%);
            background: $gray-200;
        }
        &.active {
            // background: lighten($blue, 15%);
            background: $green;

            color: $white !important;

            i {
                // color: $white;
            }


        }
        &:last-child {
            border-right: solid 1px rgba(0,0,0,.05);
        }

    }
    @media(max-width: 850px) {
        .nav-link {
            font-size: $font-size-base * 0.9;
            padding-left: $spacer * 1 !important;
            padding-right: $spacer * 1 !important;

        }
    }

}
@media(max-width: 1000px) {

    .under-nav {
        top: 50px;
    }

}

@media(max-width: 820px) {

    .under-nav {

        .nav-link {
            text-align: center;
            padding-top: 14px;;
        }

        i {
            display: block;
        }

    }

}

.navbar-top.border-bottom.navbar {
    border-color: black !important;
}

.navbar-mobile {

    background: $primary;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;

}

.navbar-mobile-content {

    background: lighten($primary, 5%);
    border-top: solid 1px white;
    position: fixed;
    top: 56px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    overflow-y: auto;

    ul {
        padding-left: 0;

        li {
            list-style: none;

            &.active {
                background: darken($primary, 0%);
            }
            &.divider {
                background: linear-gradient(to right, darken($primary, 5%), darken($primary, 12%));
                border-top: solid 1px rgba(255,255,255,.5);
                border-bottom: solid 1px rgba(255,255,255,.5);
            }

            .badge {
                background: $green;
                float: right;
                color: white;
                display: inline-block;
                width: 50px;
                font-weight: bold;
                font-size: .7rem !important;
            }

            a {
                color: white;
                display: block;
                padding: $spacer * 1.5 $spacer * 2;


                i {
                    display: inline-block;
                    width: 30px;
                }

            }

        }

    }

}


.dashboard-nav {

    .card-navigation {

        .col-md-3:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)) {

            margin-top: 15px;

        }

    }

}


#nav-contact-search-input {

    

    .object-search-input, .form-group {
        display: inline;
    }

    .object-search-input {


        .results {
            left: 20px;
            top: 25px;

            -webkit-box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
            box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
        }


        
    }

    label {
        display: none
    }

    input {
        min-height: 14px;
        background-color: $gray-100;
        border-radius: 20px;
        font-size: .8rem;
        border: none;
        display: inline-block;
        width: 265px;
        padding-left: 15px;
        padding-top: 0;;
        padding-bottom: 0;;
        height: 30px;

        &::placeholder {
            color: $gray-700;
        }
    }
    

}


.right-icon {
    color: white;
    transition: all .1s;

    &.fa-plus {
        border: solid 1px $tealOriginal;
        color: $tealOriginal !important;
        padding: 4px 8px;
        margin-top: -6px;
        position: relative;
        top: 1px;

        &:hover {
            border: solid 1px lighten($tealOriginal, 20%);
            color: lighten($tealOriginal, 20%) !important;
        }

    }

    &.fa-cogs {

        // border: solid 1px $orange;
        // color: $orange !important;

        &:hover {
            color: $white !important;
        }

    }

    &.ni-calendar-grid-58 {
        margin-left: 0;
        position: relative;
        top: 2px;

        // border: solid 1px $green;
        // color: $green !important;

        &:hover {
            color: $white !important;
        }

    }

}
