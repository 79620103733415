.dashboard-query-creator {

    $analyticsSidebarWidth: 0px;
    $headerHeight: $fixed-header-height + 3px;
    $querySidebarWidth: 200px;
    $totalWidth: 400px;

    .archk-query-creator { 
        .archk-query-creator-left {

            left: $analyticsSidebarWidth;
            top: $headerHeight;

        }

        .archk-query-creator-right {
            position: static;
            margin-left: 200px;
            max-width: calc(100% - 200px);
            padding-top: 1px;
        }

    }

}

.archk-query-creator {

    $analyticsSidebarWidth: 50px;
    $headerHeight: 53px;
    $querySidebarWidth: 200px;
    $totalWidth: 400px;

    .archk-query-creator-left {

        position: fixed;
        left: $analyticsSidebarWidth;
        top: $headerHeight;
        bottom: 0;
        width: $querySidebarWidth;
        padding-left: 0;
        padding-right: 0;
        z-index: 1000;
        border-left: solid 1px $gray-200;
        border-right: solid 1px $gray-200;
        overflow: auto;
    }
    
    .archk-query-creator-right {

        position: absolute;
        left: $analyticsSidebarWidth + $querySidebarWidth;
        right: 100px;
        top: $headerHeight;
        bottom: 0;
        width: 100%;
        max-width: calc(100% - 250px);

        padding-left: 0;
        // padding-right: 0;
        overflow: auto;

        // margin-left: $querySidebarWidth;

        .card {
            border-radius: 0;
            border-left: none;
            border-top: none;
        }

        .btn {
            font-size: 12px;
            line-height: 0;
        }

        
    }

    .archk-query-creator-title {
        // border-bottom: solid 1px $gray-100;
        margin-bottom: 2px;
    }

    .archk-query-creator-section {
        padding: $spacer * .75 $spacer * 1.5;
        border-bottom: solid 1px $gray-200;
    }

    .form-group {
        margin-bottom: $spacer / 2;
    }

    .form-control, .dataTables_filter input {
        font-size: 12px;
        min-height: 16px;
        padding: 3px 8px;
        height: auto;
    }

    .form-control-label, label {
        margin-bottom: 0;
        font-size: 13px;
    }

    .date-picker-wrapper input {
        display: block;
        outline: none;
        font-size: 12px;
        min-height: 16px;
        padding: 3px 8px;
        width: 100%;
        height: auto;
        border: solid 1px;
        color: $gray-600;
        border-radius: $border-radius-sm;
        border-color: $gray-110;
    }

    

}