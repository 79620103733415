.modal {

    // fix bug in safari of modals not being in the correct position
    &.show .modal-dialog {
        -webkit-transform: none;
        transform: none;
        transition: none;
    }

    .modal-body, .modal-header {
        padding: $card-spacer-y $card-spacer-x;
    }

    .modal-header {
        border-bottom: solid 1px $gray-300;
        min-height: 40px;
    
        .modal-title {
            margin-top: 4px;
        }
        
    }

    .modal-footer {
        border-top: solid 1px $gray-300;
        min-height: 46px;
    }
    
    .modal-content {
        max-height: 90vh;
        overflow: auto;
        max-width: 100%;
    }

}

.modal-backdrop.show {
    opacity: 0.6;
}

.fade {
    transition: opacity 0.00s linear !important;

    &.show .modal-dialog {
        animation-duration: 0.0s !important;
    }

}


.modal-emails {

    body {
        min-height: 0;
        background-color: $white;
    }

    .footer {
        position: sticky;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -7px rgba(0,0,0,0.2);
    }

}


@media (max-width: 1200px) {
    .modal-dialog.modal-lg {
        max-width: 95%;
    }
}

.modal-help.modal-dialog.modal-lg {
        width: 1400px !important;
        max-width: 90% !important;

}

.modal.freeflow {
    left: auto;
}

.modal-can-scroll {
    overflow: auto !important;

    .modal-backdrop.show {
        background-color: rgba(0,0,0,0);
    }
}